import React from 'react'
import Layout from '../components/LayoutView'
import { graphql, Link } from 'gatsby'

const Terms = () => {
 
  return (
    <Layout title='TERMS OF USE'>
      
      <div>
        <div className='user-list-area pd-top-90 pd-bottom-70'>
          <div className='container'>
            <div className='section-title text-center'>
              <h2 className='title'>TERMS OF USE</h2>
            <p>Welcome to OwnAHome.ng, a real estate website where you can find and explore homes, apartments, and other properties.
                By accessing or using our services, you agree to be bound by the following Terms of Use. 
                If you do not agree to these Terms, please do not use our services. </p>
            
            </div>
            
            
<h3>1. ACCEPTANCE OF TERMS </h3>
<p>By accessing or using our services, you agree to be bound by these Terms of Use, as well as our Privacy Policy and any additional terms and conditions that may apply to specific sections of our website or to particular services that we offer. 
    If you do not agree to these Terms, you may not access or use our services.
</p>

<h3>2. DESCRIPTION OF SERVICES</h3>
<p>Our website provides information and tools to help you find and explore homes, apartments, and other properties. 
    We do not own, manage, or represent any of the properties listed on our website. We do not guarantee the accuracy or completeness of any information or content on our website, and we are not responsible for any errors or omissions.
</p>

<h3>3. USER ACCOUNTS</h3>
<p>You may be required to create a user account in order to access certain features of our website. When creating an account, you must provide accurate and complete information. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. 
    You may not share your account information with any third party.
</p>

<h3>4. USER CONTENT</h3>
<p>You may be able to submit content to our website, such as property listings or reviews. By submitting content, you grant us a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content in any media.
You represent and warrant that you have all necessary rights to submit the content and that the content is accurate, not confidential, and not in violation of any third party's rights. You agree to indemnify and hold us harmless from any claims or damages arising from your content.
</p>

<h3>5. PROHIBITED USES</h3>
<p>You may not use our services for any unlawful purpose or in any manner that could damage, disable, overburden, or impair our website or services. 
   You may not use automated means, such as robots or spiders, to access our website or services. You may not impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity.
</p>

<h3>6. INTELLECTUAL PROPERTY</h3>
<p> Our website and services are protected by copyright, trademark, and other intellectual property laws.
    You may not copy, modify, distribute, or create derivative works from our website or services without our prior written consent.</p>

<h3>7. DISCLAIMER OF WARRANTIES</h3>
<p> Our website and services are provided "as is" and without warranties of any kind, either express or implied. 
    We do not guarantee that our website or services will be error-free, uninterrupted, or free from viruses or other harmful components. 
    We do not endorse or make any representations about any third-party content or websites.</p>

   <h3>8. LIMITATION OF LIABILITY </h3> 
<p> We shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of our website or services, even if we have been advised of the possibility of such damages. 
In no event shall our liability exceed the amount you paid us, if any, for accessing our website or services. </p>

<h3>9.INDEMNIFICATION </h3>
<p> You agree to indemnify and hold us harmless from any claims, damages, or expenses arising from your use of our website or services, your content, or your violation of these Terms of Use.</p>

<h3>10. MODIFICATIONS TO TERMS OF USE</h3>
<p>We reserve the right to modify these.</p>


          </div>
        </div>
      </div>
    </Layout>
  )
}


export default Terms
